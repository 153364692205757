export default function populateUtmFields() {
  console.log(`loads`); // Confirms the main function runs

  // Function to get UTM parameters from the current URL
  function getUrlParams() {
    console.log(`collecting UTM parameters`);
    const params = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_campaign: params.get('utm_campaign'),
      utm_content: params.get('utm_content'),
      utm_medium: params.get('utm_medium'),
      utm_term: params.get('utm_term'),
      utm_source: params.get('utm_source'),
    };
    console.log('UTM Parameters:', utmParams);
    return utmParams;
  }

  // Function to populate hidden fields in all forms
  function populateFields() {
    console.log(`populateFields function fired`);
    const utmParams = getUrlParams();
    const forms = document.querySelectorAll('form');
    console.log(`Number of forms found:`, forms.length);

    if (forms.length === 0) {
      console.warn(`No forms found on the page!`);
      return; // Exit early if no forms found
    }

    // Loop through each form for hidden UTM fields
    forms.forEach((form, index) => {
      console.log(`Processing form #${index + 1}`);

      // Populate UTM fields if the params are present
      if (utmParams.utm_campaign) {
        const campaignField = form.querySelector('input[name="utm_campaign"]');
        if (campaignField) {
          campaignField.value = utmParams.utm_campaign;
          console.log(`campaign field set for form #${index + 1}:`, utmParams.utm_campaign);
        } else {
          console.log(`No campaign field found in form #${index + 1}`);
        }
      }

      if (utmParams.utm_source) {
        const sourceField = form.querySelector('input[name="utm_source"]');
        if (sourceField) {
          sourceField.value = utmParams.utm_source;
          console.log(`source field set for form #${index + 1}:`, utmParams.utm_source);
        } else {
          console.log(`No source field found in form #${index + 1}`);
        }
      }

      if (utmParams.utm_content) {
        const contentField = form.querySelector('input[name="utm_content"]');
        if (contentField) {
          contentField.value = utmParams.utm_content;
          console.log(`content field set for form #${index + 1}:`, utmParams.utm_content);
        } else {
          console.log(`No content field found in form #${index + 1}`);
        }
      }

      if (utmParams.utm_medium) {
        const mediumField = form.querySelector('input[name="utm_medium"]');
        if (mediumField) {
          mediumField.value = utmParams.utm_medium;
          console.log(`medium field set for form #${index + 1}:`, utmParams.utm_medium);
        } else {
          console.log(`No medium field found in form #${index + 1}`);
        }
      }

      if (utmParams.utm_term) {
        const termField = form.querySelector('input[name="utm_term"]');
        if (termField) {
          termField.value = utmParams.utm_term;
          console.log(`term field set for form #${index + 1}:`, utmParams.utm_term);
        } else {
          console.log(`No term field found in form #${index + 1}`);
        }
      }
    });
  }

  // Use window.onload to ensure all assets are loaded
  window.onload = populateFields;
}
